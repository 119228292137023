@use 'styles/scrollbar.scss';
@use '@reply-pro/component-library/abstracts' as *;



.app-wrapper {
  height: 100vh;
  max-height: 100vh;
  max-width: 100%;
  min-width: 1150px;
  display: flex;
  flex-direction: column;
    
  main {
    flex-grow: 1;
    display: flex;

    .context-screen {
      flex-grow: 1;
      max-height: 100vh;

      .common-page {
        display: flex;
        align-items: stretch;
        height: 100%;
        padding-right: $element-padding-md;
        padding-top: $element-padding-md;
        padding-bottom: $element-padding-md;

        .common-primary-view {
          flex-grow: 1;
          display: flex;
          align-items: stretch;

          .primary-card {
            border: 1px solid $borderLine;
            border-radius: 20px;
            background: $white;
            align-items: stretch;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@mixin centralized_feedback(){
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.central-loading-spinner{
  color: $main;
  @include centralized_feedback();
}

.no-items-found {
  color: $gray;
  @include centralized_feedback();
}