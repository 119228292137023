@use '@reply-pro/component-library/abstracts' as *;

.features-setter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-small-sm;

  .features-toggle-input-setter {
    display: flex;
    align-items: center;
  }

  .user-group-warning {
    width: 18px;
    height: 18px;
    color: $gray;
  }

  .features-setter-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-small-xs;

    &.not-features {
      pointer-events: none;
      opacity: 0.3;
    }

    .feature-button {
      @include text-sm-reg;
      padding: $element-padding-xs $element-padding-sm;
      border-radius: $border-radius-md;
      color: $gray;
      display: flex;
      align-items: center;
      gap: $spacing-small-xxs;
  
      svg {
        margin: $margin-null;
      }
  
      &:not(.not-allowed):not(.disabled) {
        &:hover, &:focus, &:focus-within {
          background: $white !important;
        }
      }
  
  
      &.not-allowed {
        background: $lightestGray;
        border-color: $lightestGray;
        cursor: pointer !important;
        
        &:hover, &:focus {
          background: $lightestGray;
          border-color: $lightestGray;
        }
  
        &.warning {
          color: $orange;
        }
      }
  
      &.disabled {
        cursor: not-allowed;
  
        &:hover, &:focus {
          background: $white;
        }
  
  
      }
  
      &.warning {
        @include text-sm-med;
        transition: color 300ms;
  
        &:hover {
          color: $orange;
        }
      }
    }
  }
}